import React, { useEffect } from "react";
import { Page } from "../../components/Page";
import { Section } from "../../components/Section";
import { Seo } from "../../components/Seo";
import { Slider } from "../../components/Slider";
import { ArticleCard } from "../../components/ArticleCard";
import { Button, ButtonType } from "../../components/Button";
import { CompanyTemplateData } from "../Company/data";
import { AnimatePresence, motion } from "framer-motion";
import * as JsSearch from "js-search";
import { ScrollContainer } from "react-indiana-drag-scroll";
import "react-indiana-drag-scroll/dist/style.css";

import * as classes from "./style.module.css";

interface CompanyListingTemplateProps {
  pageContext: {
    companies: CompanyTemplateData[];
  };
}

interface FilterOption {
  label: string;
  selected: boolean;
  relatedCompanyIds: string[];
}

export default function CompanyListingTemplate(
  props: CompanyListingTemplateProps
): React.ReactElement {
  const ARTICLES_PER_PAGE = 100;
  const companies = props.pageContext.companies;
  const [filterOptions, setFilterOptions] = React.useState<FilterOption[]>(
    extractFilterOptions(companies)
  );
  // const [cityFilterOptions, setCityFilterOptions] = React.useState<
  //   FilterOption[]
  // >(extractCityFilterOptions(companies));
  // const [countryFilterOptions, setCountryFilterOptions] = React.useState<
  //   FilterOption[]
  // >(extractCountryFilterOptions(companies));

  const [shownCompaniesNumber, setShownCompaniesNumber] =
    React.useState<number>(ARTICLES_PER_PAGE);

  const [companyList, setCompanyList] = React.useState<any>(companies);
  const [search, setSearch] = React.useState<any>([]);
  const [searchResults, setSearchResults] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isError, setIsError] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState("");

  useEffect(() => {
    setCompanyList(props.pageContext.companies);
    rebuildIndex();
    console.log("useEffect completed");
  }, []);

  /**
   * rebuilds the overall index based on the options
   */
  const rebuildIndex = () => {
    const dataToSearch = new JsSearch.Search("name") as any;

    /**
     * defines an indexing strategy for the data
     * more about it in here https://github.com/bvaughn/js-search#configuring-the-index-strategy
     */
    dataToSearch._indexStrategy = new JsSearch.PrefixIndexStrategy();
    /**
     * defines the sanitizer for the search
     * to prevent some of the words from being excluded
     *
     */
    dataToSearch._sanitizer = new JsSearch.LowerCaseSanitizer();
    /**
     * defines the search index
     * read more in here https://github.com/bvaughn/js-search#configuring-the-search-index
     */
    dataToSearch._searchIndex = new JsSearch.TfIdfSearchIndex("name");

    dataToSearch.addIndex("name"); // sets the index attribute for the data
    dataToSearch.addIndex("city"); // sets the index attribute for the data
    dataToSearch.addIndex("tags"); // sets the index attribute for the data
    dataToSearch.addIndex("categories"); // sets the index attribute for the data

    dataToSearch.addDocuments(companyList); // adds the data to be searched

    setSearch(dataToSearch);
    setIsLoading(false);
  };

  /**
   * handles the input change and perform a search with js-search
   * in which the results will be added to the state
   */
  const searchData = (e) => {
    console.log('Search: ', search);
    const queryResult = search.search(e.target.value);
    console.log('Query Result: ', queryResult);
    setSearchQuery(e.target.value);
    setSearchResults(queryResult);
  };

  function handleFilterOptionClick(optionLabel: string): void {
    const updatedFilterOptions = [...filterOptions];
    const selectedOptionIndex = updatedFilterOptions
      .map((o) => o.label)
      .indexOf(optionLabel);
    updatedFilterOptions[selectedOptionIndex].selected =
      !updatedFilterOptions[selectedOptionIndex].selected;
    setFilterOptions(updatedFilterOptions);
  }

  // function handleCityFilterOptionClick(optionLabel: string): void {
  //   const updatedFilterOptions = [...cityFilterOptions];
  //   const selectedOptionIndex = updatedFilterOptions
  //     .map((o) => o.label)
  //     .indexOf(optionLabel);
  //   updatedFilterOptions[selectedOptionIndex].selected =
  //     !updatedFilterOptions[selectedOptionIndex].selected;
  //   setCityFilterOptions(updatedFilterOptions);
  // }

  // function handleCountryFilterOptionClick(optionLabel: string): void {
  //   const updatedFilterOptions = [...countryFilterOptions];
  //   const selectedOptionIndex = updatedFilterOptions
  //     .map((o) => o.label)
  //     .indexOf(optionLabel);
  //   updatedFilterOptions[selectedOptionIndex].selected =
  //     !updatedFilterOptions[selectedOptionIndex].selected;
  //   setCountryFilterOptions(updatedFilterOptions);
  // }

  function handleLoadMoreButtonClick(
    companiesNumber: number,
    selectedCompaniesNumber?: number
  ): void {
    const incrementedArticleNumber = shownCompaniesNumber + 3;
    if (
      selectedCompaniesNumber &&
      selectedCompaniesNumber >= incrementedArticleNumber
    ) {
      setShownCompaniesNumber(incrementedArticleNumber);
    } else if (
      !selectedCompaniesNumber &&
      companiesNumber >= incrementedArticleNumber
    ) {
      setShownCompaniesNumber(incrementedArticleNumber);
    }
  }

  // Check if at least one filter option is selected. If so, create an array of all article ids that
  // are selected based on the current filter option selection. We use this later on to easily check
  // which companies to show.
  let selectedCompanyIds: string[] = [];
  const filterSelected =
    filterOptions.map((o) => o.selected).indexOf(true) !== -1;
  if (filterSelected) {
    selectedCompanyIds = filterOptions
      .filter((option) => option.selected) // Filter only for selected options
      .map((option) => option.relatedCompanyIds) // Create an array of company ids arrays
      .flat(1) // Flatten the array to a string[]
      .filter((id, index, arr) => arr.indexOf(id) === index); // Remove duplicate article ids
  }

  // const cityFilterSelected =
  //   cityFilterOptions.map((o) => o.selected).indexOf(true) !== -1;
  // if (cityFilterSelected) {
  //   selectedCompanyIds = cityFilterOptions
  //     .filter((option) => option.selected) // Filter only for selected options
  //     .map((option) => option.relatedCompanyIds) // Create an array of company ids arrays
  //     .flat(1) // Flatten the array to a string[]
  //     .filter((id, index, arr) => arr.indexOf(id) === index); // Remove duplicate article ids
  // }

  // const countryFilterSelected =
  //   countryFilterOptions.map((o) => o.selected).indexOf(true) !== -1;
  // if (countryFilterSelected) {
  //   selectedCompanyIds = countryFilterOptions
  //     .filter((option) => option.selected) // Filter only for selected options
  //     .map((option) => option.relatedCompanyIds) // Create an array of company ids arrays
  //     .flat(1) // Flatten the array to a string[]
  //     .filter((id, index, arr) => arr.indexOf(id) === index); // Remove duplicate article ids
  // }

  const subheaderText = (
    <>
      <p>
        We've got a carefully curated list of companies that are already part of
        our Muzdex network!
      </p>
      <p>
        Want to join? Simply fill out our{" "}
        <a
          target="_blank"
          rel="nofollow noopener noreferrer"
          href="https://forms.gle/xfqdnFfjAh3Taeyk7"
          style={{
            textDecoration: "underline",
          }}
        >
          onboarding form
        </a>
        , and we'll be in touch!
      </p>
    </>
  );

  const isLoadingContent = (
    <div>I am loading (.)</div>
  );

  const queryResults = searchQuery === `` ? companies : searchResults

  return (
    <>
      <Seo title="All Companies" useTitleTemplate={true} />
      <Page>
        <Section
          anchor="companyListing"
          heading="Companies"
          subheader={subheaderText}
        >
          <div className={classes.Filter} id="category">
            <ScrollContainer>
              <Slider
                additionalClasses={[classes.Options, classes.NoOverflowY]}
              >
                {filterOptions
                  .sort((a, b) => {
                    if (a.label === "✊ Palestine" || a.label === "Charity") {
                      return -1;
                    } else {
                      return 1;
                    }
                  })
                  .map((option, key) => {
                    return (
                      <button
                        key={key}
                        tabIndex={0}
                        role="button"
                        onClick={() => handleFilterOptionClick(option.label)}
                        className={[
                          option.label === "✊ Palestine"
                            ? classes.Ramadan
                            : null,
                          classes.Option,
                          option.selected === true ? classes.Selected : null,
                        ].join(" ")}
                      >
                        {option.label} ({option.relatedCompanyIds.length})
                      </button>
                    );
                  })}
              </Slider>
            </ScrollContainer>
          </div>
          <div className={classes.formContainer}>
            <form
              className={classes.formNoSubmit}
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <input
                className={classes.inputNoSubmit}
                value={searchQuery}
                onChange={searchData}
                type="search"
                placeholder="Search..."
              />
            </form>
          </div>
          {isLoading ? isLoadingContent : null}
          {queryResults.length > 0 ? <div className={classes.Listing}>
            <AnimatePresence>
              {queryResults
                .filter(
                  (company) =>
                    (!filterSelected) ||
                    selectedCompanyIds.includes(company.id)
                )
                .slice(0, shownCompaniesNumber)
                .map((company, key) => {
                  return (
                    <motion.div
                      className={classes.Listing}
                      key={key}
                      style={{ overflow: "hidden" }}
                      layout
                      initial={{ transform: "scale(0)" }}
                      animate={{ transform: "scale(1)" }}
                      exit={{ transform: "scale(0)" }}
                    >
                      <ArticleCard
                        key={key}
                        showBanner={true}
                        data={{
                          image: company.banner,
                          title: company.name,
                          //category: company.tags.join(" / "),
                          link: company.slug,
                          isCompany: true,
                          brief: company.brief,
                        }}
                      />
                    </motion.div>
                  );
                })}
            </AnimatePresence>
          </div> : <h2 style={{textAlign: 'center'}}>No results 😔</h2>}
          {(filterSelected &&
            selectedCompanyIds.length > shownCompaniesNumber) ||
          (!filterSelected && companies.length > shownCompaniesNumber) ? (
            <div className={classes.LoadMore}>
              <Button
                type={ButtonType.BUTTON}
                label="Load More"
                onClickHandler={() =>
                  handleLoadMoreButtonClick(
                    companies.length,
                    filterSelected ? selectedCompanyIds.length : undefined
                  )
                }
              />
            </div>
          ) : null}
        </Section>
      </Page>
    </>
  );
}

// Helper function to calculate a sorted array of filter options based on the given companies
// We use the helper function before we initialize the state so that it can happen on the server.
function extractFilterOptions(
  companies: CompanyTemplateData[]
): FilterOption[] {
  const filterOptions: FilterOption[] = [];
  const categoryList: string[] = [];
  companies.forEach((company) => {
    company.categories.forEach((tag) => {
      if (!categoryList.includes(tag)) {
        filterOptions.push({
          label: tag,
          selected: false,
          relatedCompanyIds: [company.id],
        });
        categoryList.push(tag);
      } else {
        const optionIndex = filterOptions.map((o) => o.label).indexOf(tag);
        filterOptions[optionIndex].relatedCompanyIds.push(company.id);
      }
    });
  });
  return filterOptions.sort((a, b) =>
    a.relatedCompanyIds.length > b.relatedCompanyIds.length ? -1 : 1
  );
}

// Helper function to calculate a sorted array of filter options based on the given companies
// We use the helper function before we initialize the state so that it can happen on the server.
function extractCityFilterOptions(
  companies: CompanyTemplateData[]
): FilterOption[] {
  const cityFilterOptions: FilterOption[] = [];
  const cityCategoryList: string[] = [];
  companies.forEach((company) => {
    if (!cityCategoryList.includes(company.city)) {
      cityFilterOptions.push({
        label: company.city,
        selected: false,
        relatedCompanyIds: [company.id],
      });
      cityCategoryList.push(company.city);
    } else {
      const optionIndex = cityFilterOptions
        .map((o) => o.label)
        .indexOf(company.city);
      cityFilterOptions[optionIndex].relatedCompanyIds.push(company.id);
    }
  });
  return cityFilterOptions.sort((a, b) =>
    a.relatedCompanyIds.length > b.relatedCompanyIds.length ? -1 : 1
  );
}

// Helper function to calculate a sorted array of filter options based on the given companies
// We use the helper function before we initialize the state so that it can happen on the server.
function extractCountryFilterOptions(
  companies: CompanyTemplateData[]
): FilterOption[] {
  const countryFilterOptions: FilterOption[] = [];
  const countryCategoryList: string[] = [];
  companies.forEach((company) => {
    if (!countryCategoryList.includes(company.country)) {
      countryFilterOptions.push({
        label: company.country,
        selected: false,
        relatedCompanyIds: [company.id],
      });
      countryCategoryList.push(company.country);
    } else {
      const optionIndex = countryFilterOptions
        .map((o) => o.label)
        .indexOf(company.country);
      countryFilterOptions[optionIndex].relatedCompanyIds.push(company.id);
    }
  });
  return countryFilterOptions.sort((a, b) =>
    a.relatedCompanyIds.length > b.relatedCompanyIds.length ? -1 : 1
  );
}
